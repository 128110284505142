import { Fragment, useEffect } from "react";
import { useSpring } from "@react-spring/core";
import { a } from "@react-spring/three";
import { useLoader } from "@react-three/fiber";
import * as THREE from "three";

import { useStore } from "@state/store";
import config from "../../config";
import { useVideoTexture } from "@react-three/drei";

function SphereManager() {
  const socket = useStore((s) => s.socket);
  const currentTour = useStore((s) => s.currentTour);
  const setCurrentTour = useStore((s) => s.setCurrentTour);

  const textures = [
    {
      texture: "/textures/1.mp4",
      rotation: [0, 25.5, 0],
    },
    {
      texture: "/textures/2.mp4",
      rotation: [0, 5.2, 0],
    },
    {
      texture: "/textures/3.mp4",
      rotation: [0, 3.9, 0],
    },
    {
      texture: "/textures/1.mp4",
      rotation: [0, 0.85, 0],
    },
    {
      texture: "/textures/1.mp4",
      rotation: [0, 2, 0],
    },
    {
      texture: "/textures/1.mp4",
      rotation: [0, 1.35, 0],
    },
    {
      texture: "/textures/1.mp4",
      rotation: [0, 20.1, 0],
    },
  ].map((obj) => {
    return {
      // @ts-ignore
      texture: useVideoTexture(obj.texture),
      rotation: obj.rotation,
    };
  });

  textures.forEach((obj) => {
    obj.texture.minFilter = THREE.LinearFilter;
    obj.texture.wrapS = THREE.RepeatWrapping;
    obj.texture.repeat.x = -1;
  });

  const getSprings = () => {
    let obj = {};
    for (let i = 0; i < textures.length; i++) {
      obj[i] = useSpring({
        opacity: i === currentTour ? 1 : 0,
      });
    }
    return obj;
  };
  const springs = getSprings();

  useEffect(() => {
    socket.on("video", (data) => {
      console.log("data");
    });
  }, [socket]);

  useEffect(() => {
    socket.emit("vrTest", "test");
  }, []);

  return (
    <Fragment>
      {textures.map((obj, i) => {
        return (
          <mesh
            key={i}
            position={[0, 0, 0]}
            //@ts-ignore
            rotation={obj.rotation}
            visible={currentTour === i}
          >
            <sphereGeometry args={[400, 60, 30]} />
            {/* @ts-ignore */}
            <a.meshBasicMaterial
              toneMapped={false}
              map={obj.texture}
              side={THREE.DoubleSide}
              transparent
              opacity={springs[i].opacity}
            />
          </mesh>
        );
      })}
    </Fragment>
  );
}

export default SphereManager;
